import { Env } from '../common/types';
import { currentEnv } from '../common/env';

interface IGatewayConfig {
  gamingUrl: string;
  mirageIdUrl: string;
  nftManagerUrl: string;
  marketPlaceUrl: string;
}

interface IWeb3Config {
  ethChainId: number;
  ethSepoliaChainId: number;
  polygonChainId: number;
  bscChainId: number;
}

interface IConfig {
  gatewayConfig: IGatewayConfig;
  web3Config: IWeb3Config;
  oryUrl: string;
}

const PROD_CONFIG: IConfig = {
  gatewayConfig: {
    gamingUrl: 'https://mosaic-game-services.prod.mirage.xyz/api/',
    mirageIdUrl: 'https://mirage-id.prod.mirage.xyz/api/',
    nftManagerUrl: 'https://nft-manager.prod.mirage.xyz/api/',
    marketPlaceUrl: 'https://mosaic-marketplace.prod.mirage.xyz/api/',
  },
  web3Config: {
    // TODO: change it to mainnet in the near future
    ethChainId: 1, // mainnet
    ethSepoliaChainId: 11155111, // sepoia
    polygonChainId: 137, // mainnet
    bscChainId: 97, // testnet
  },
  oryUrl: 'https://auth.mirage.xyz',
};

const STAGE_CONFIG: IConfig = {
  gatewayConfig: {
    gamingUrl: 'https://mosaic-game-services.staging.mirage.xyz/api/',
    mirageIdUrl: 'https://mirage-id.staging.mirage.xyz/api/',
    nftManagerUrl: 'https://nft-manager.staging.mirage.xyz/api/',
    marketPlaceUrl: 'https://mosaic-marketplace.staging.mirage.xyz/api/',
  },
  web3Config: {
    ethChainId: 5, // goerli
    ethSepoliaChainId: 11155111, // sepoia
    polygonChainId: 137, // mainnet
    bscChainId: 97, // testnet
  },
  oryUrl: 'https://ory.staging.mirage.xyz',
};

const BS_CONFIG: IConfig = {
  gatewayConfig: {
    gamingUrl: 'https://mosaic-game-services.dev.staging.mirage.xyz/api/',
    mirageIdUrl: 'https://mirage-id.dev.staging.mirage.xyz/api/',
    nftManagerUrl: 'https://nft-manager.dev.staging.mirage.xyz/api/',
    marketPlaceUrl: 'https://mosaic-marketplace.dev.staging.mirage.xyz/api/',
  },
  web3Config: {
    ethChainId: 5, // goerli
    ethSepoliaChainId: 11155111, // sepoia
    polygonChainId: 137, // mainnet
    bscChainId: 97, // testnet
  },
  oryUrl: 'https://ory.staging.mirage.xyz',
};

const LOCAL_CONFIG: IConfig = {
  // If the APIs don't work, check that kubectl port-forward is running on correct ports!
  gatewayConfig: {
    gamingUrl: 'http://localhost:6001/api/',
    mirageIdUrl: 'http://localhost:5001/api/',
    nftManagerUrl: 'http://localhost:7001/api/',
    marketPlaceUrl: 'https://mosaic-marketplace.staging.mirage.xyz/api/',
  },
  web3Config: {
    ethChainId: 5, // goerli
    ethSepoliaChainId: 11155111, // sepoia
    polygonChainId: 137, // mainnet
    bscChainId: 97, // testnet
  },
  // oryUrl: "https://ory.dev.staging.mirage.xyz"
  oryUrl: 'http://localhost:4000',
};

export function configFromEnv(env = currentEnv): IConfig {
  switch (env) {
    case Env.Prod:
      return PROD_CONFIG;
    case Env.Stage:
      return STAGE_CONFIG;
    case Env.BS:
      return BS_CONFIG;
    default:
      return LOCAL_CONFIG;
  }
}
